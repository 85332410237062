body {
	background-color: #fff;
	color: $color4;
}

* { box-sizing: border-box; }

img {
	width: 100%;
	height: auto;
	display: inherit;
}

a {
	text-decoration: none;
}

