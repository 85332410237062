.bt {
	border: 1px solid $color1;
	display: inline-block;
	text-align: center;
	padding: 15px 25px;
	font-family: 'Source Sans Pro', sans-serif;
	color: $color4;
	transition: all 0.2s ease;
	background-color: #fff;
	cursor: pointer;

	p {
		transition: all 0.2s ease;
		font-weight: 600;
	}

	&:hover {
		background-color: $color1;
		color: #fff;
	}
}