
.title {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	line-height: 1.2;

	&.medium {
		font-size: 25px;
		margin-bottom: 30px;
		color: #2C7751;
		font-weight: 600;
	}

	&.small {
		font-size: 18px;
		margin-bottom: 0;
		color: #2C7751;
	}

	@media screen and (max-width: 1000px) {
		&.medium {
			font-size: 20px;
		}
	}
}

.regular-text {
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 400;
	line-height: 1.8;
	font-size: 16px;

	@media screen and (max-width: 1000px) {
		font-size: 14px;
	}
}

$color1: #2C7751;
$color2: #9FCC3B;
$color3: #F6F9F7;
$color4: #323639;

$colorAccent1: #2C7751;
$colorAccent2: #9FCC3B;
$colorAccent3: #F6F9F7;
$colorAccent4: #323639;

$dark: #314c4a;


